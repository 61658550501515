import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import HTML5Backend from 'react-dnd-html5-backend';

import Router from './Router';
import configureStore from './store';
import './index.scss';
import { getFirebase } from 'react-redux-firebase';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';

// types not available
const dnd = require('react-dnd');

const store = configureStore({});

window.addEventListener('unhandledrejection', (err: PromiseRejectionEvent) => {
  console.error(err.reason);
});

const renderLoadingScreen = () => {
  ReactDOM.render(<LoadingScreen />, document.getElementById(
    'root'
  ) as HTMLElement);
};

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <dnd.DragDropContextProvider backend={HTML5Backend}>
        <Router />
      </dnd.DragDropContextProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement
  );
};

renderLoadingScreen();

getFirebase()
  .auth()
  .getRedirectResult()
  .then(() => renderApp())
  .catch((error: Error) => {
    console.error('Unable to authenticate', {
      extra: {
        reason: error
      }
    });
    renderApp();
  });
