import * as React from 'react';

import './AboutModal.scss';
import Modal from '../Modal';
import classNames = require('classnames');

export type TabType = 'about' | 'changelog';

export interface AboutModalProps {
  onClose: () => void;
}

export interface AboutModalState {
  showTab: TabType;
  email: string;
  messageBody: string;
}

export class AboutModal extends React.Component<
  AboutModalProps,
  AboutModalState
> {
  constructor(props: AboutModalProps) {
    super(props);
    this.state = { showTab: 'about', email: '', messageBody: '' };
  }

  setTab = (tab: TabType) => {
    this.setState({ showTab: tab });
  };

  setEmail = (e: any) => {
    this.setState({ ...this.state, email: e.target.value });
  };

  setMessageBody = (e: any) => {
    this.setState({ ...this.state, messageBody: e.target.value });
  };

  sendEmailOnClose = () => {
    if (!!this.state && !!this.state.messageBody) {
      const text =
        (this.state.email
          ? `_${this.state.email}_ wrote:\n> `
          : 'Someone wrote:\n> ') + this.state.messageBody;

      console.log(text);
    } else {
      this.props.onClose();
    }
  };

  render() {
    const showAbout = this.state.showTab === 'about';

    return (
      <Modal onClose={this.props.onClose} onSubmit={this.sendEmailOnClose}>
        <div className="about-modal__tabs">
          <button
            className={classNames('about-modal__tab-button', {
              'about-modal__tab-button--active': showAbout
            })}
            onClick={e => this.setTab('about')}
          >
            About
          </button>
        </div>

        {showAbout && (
          <div className="about-modal__content">
            <div className="about-modal__panel">
              <h2 className="about-modal__headline">About</h2>

              <p>Scrumlr is an open source project.</p>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default AboutModal;
